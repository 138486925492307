import React, { useEffect, useState } from 'react';
import { Button, Flex, Text, List, ListItem, Box } from '@chakra-ui/react';
import { BiHomeAlt2, BiTrip } from 'react-icons/bi';
import { BiWallet } from 'react-icons/bi';
import { LiaFileInvoiceSolid } from 'react-icons/lia';
import { useRouter } from 'next/router';
import { useApolloClient } from '@apollo/client';
import Link from 'next/link';
import Image from 'next/image';
import { RiMapPin5Line } from 'react-icons/ri';
import { TbRosetteDiscountCheck } from 'react-icons/tb';
import { GET_CLIENT } from '../../apollo/queries/GetClient';
import { GET_BOOKER } from '../../apollo/queries/GetBooker';
import useAuthInfo from '../../hooks/useAuthInfo';

const items = [
  {
    id: 1,
    title: 'Dashboard',
    icon: <BiHomeAlt2 size={'20px'} />,
    url: 'dashboard',
    roles: ['individual', 'business', 'booker'],
  },
  {
    id: 2,
    title: 'New Order',
    icon: <RiMapPin5Line size={'20px'} />,
    url: 'order',
    roles: ['individual', 'business', 'booker'],
  },
  {
    id: 3,
    title: 'Trips',
    icon: <BiTrip size={'20px'} />,
    url: 'trips',
    roles: ['individual', 'business', 'booker'],
  },
  {
    id: 4,
    title: 'Invoices',
    icon: <LiaFileInvoiceSolid size={'20px'} />,
    url: 'invoices',
    roles: ['individual', 'business', 'booker'],
  },
  {
    id: 5,
    title: 'Wallets',
    icon: <BiWallet size={'20px'} />,
    url: 'wallet',
    roles: ['individual', 'business', 'booker'],
  },
  {
    id: 6,
    title: 'Promotions',
    icon: <TbRosetteDiscountCheck size={'20px'} />,
    url: 'promotions',
    roles: ['business_advertiser'],
  },
];

type User = {
  type: string;
  business: any;
};

const SideNavBar = () => {
  const router = useRouter();

  const [user, setUser] = useState<User>({
    type: '',
    business: {},
  });
  const [loading, setLoading] = useState(true);

  const apolloClient = useApolloClient();

  const { role: userType } = useAuthInfo();

  useEffect(() => {
    if (userType === 'booker') {
      apolloClient
        .query({
          query: GET_BOOKER,
          context: {
            clientName: 'vert',
          },
        })
        .then(({ data }) => setUser({ ...data.getBooker, type: 'booker' }))
        .catch((e) => console.log(e));
    }
    if (userType === 'client') {
      apolloClient
        .query({
          query: GET_CLIENT,
          context: {
            clientName: 'vert',
          },
        })
        .then(({ data }) => setUser(data.getClient))
        .catch((e) => console.log(e));
    }
    setLoading(false);
  }, [userType]);

  useEffect(() => {
    checkTheme();
  }, []);

  const checkTheme = () => {
    const body = document.querySelector('body');
    body.classList.remove('dark');
    if (localStorage.getItem('vert-theme') !== null) {
      if (localStorage.getItem('vert-theme') === 'dark') {
        body.classList.add('dark');
      } else {
        body.classList.remove('dark');
      }
    }
  };

  return (
    <Flex
      position={'fixed'}
      backgroundColor={'white'}
      shadow={'md'}
      h={'100%'}
      w={'90px'}
      py={'25px'}
      justify={'center'}
      zIndex={2}
    >
      {!loading && (
        <List p={0}>
          <ListItem pb={'80px'}>
            <Link href="/">
              <Image
                style={{ cursor: 'pointer' }}
                src={'/images/logo_vert.png'}
                alt="logo"
                height={25}
                width={55}
              />
            </Link>
          </ListItem>
          {items
            .filter((item) => {
              // Check if user's role matches item's role
              const roleMatches = item.roles.includes(user.type);

              // Check if item's type is "business"
              const typeIsBusiness = user.type === 'business';

              // Check if user is an advertiser
              const isAdvertiser =
                typeIsBusiness && user.business && user.business.is_advertiser;

              // Filter items based on all conditions
              return roleMatches || isAdvertiser;
            })
            .map((item, index) => (
              <Box key={index}>
                <ListItem>
                  <Button
                    name="select"
                    backgroundColor={'transparent'}
                    onClick={() => router.push(`/app/${item.url}`)}
                    fontWeight={'medium'}
                  >
                    {item.icon}
                  </Button>
                  <Text fontSize={9} textAlign={'center'}>
                    {item.title}
                  </Text>
                </ListItem>
              </Box>
            ))}
        </List>
      )}
    </Flex>
  );
};

export default SideNavBar;
