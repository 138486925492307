import Footer from '../Footer';
import NavBar from '../NavBar';
import { Flex } from '@chakra-ui/react';
import SideNavBar from '../NavBar/SideNavbar';
import AppNavBar from '../NavBar/AppNavbar';

const Layout = ({
  children,
  withNavBar = true,
  withFooter = true,
  withSideNavBar = false,
  withAppNavBar = true,
  willAnimate = false,
}) => (
  <>
    {withNavBar && <NavBar willAnimate={willAnimate} />}
    {withAppNavBar && <AppNavBar />}
    {withSideNavBar && <SideNavBar />}
    <Flex direction="column">{children}</Flex>
    {withFooter && <Footer />}
  </>
);

export default Layout;
