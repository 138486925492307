import { Flex, Box, Spinner } from '@chakra-ui/react';

const Loader = ({ isLoading, size }) => {
  if (!isLoading) {
    return null;
  }

  return (
    <Flex>
      <Box
        backgroundColor={'red'}
        style={{
          zIndex: 99999,
          width: '100%',
          position: 'fixed',
          height: '100%',
          backgroundColor: 'rgba(0,0,0,0.3)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          left: 0,
          top: 0,
        }}
      >
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="brand.900"
          size={size ? size : 'xl'}
        />
      </Box>
    </Flex>
  );
};

export default Loader;
