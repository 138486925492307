import { useEffect, useState } from 'react';
import { getAuthToken } from '../services/util/localStorage';
import { decodeToken } from '../services/auth/AuthService';

function useAuthInfo() {
  const [authInfo, setAuthInfo] = useState({ role: null, id: null });

  useEffect(() => {
    const token = getAuthToken();

    const fetchAuthInfo = async () => {
      if (token) {
        const decoded = await decodeToken(token);
        if (decoded) {
          setAuthInfo({ role: decoded.role, id: decoded.id });
        }
      }
    };

    fetchAuthInfo();
  }, []);

  return authInfo;
}

export default useAuthInfo;
