import { gql } from '@apollo/client';

export const GET_CLIENT = gql`
  query GetClient {
    getClient {
      id
      first_name
      last_name
      email
      country_code
      phone_number
      share_code
      profile_picture
      gender
      enabled
      mobile_verified
      email_verified
      type
      rating
      business_id
      stripe_id
    }
  }
`;
