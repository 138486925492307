export function setAuthToken(token: string) {
  if (typeof window !== 'undefined') {
    localStorage.setItem('vert_auth_token', token);
  }
}

export function getAuthToken() {
  if (typeof window !== 'undefined') {
    return localStorage.getItem('vert_auth_token');
  }
  return null;
}

export function clearAuthToken() {
  if (typeof window !== 'undefined') {
    localStorage.removeItem('vert_auth_token');
  }
}
