import { gql } from '@apollo/client';

export const GET_BOOKER = gql`
  query Query {
    getBooker {
      id
      first_name
      last_name
      country_code
      phone_number
      email
      enabled
      profile_picture
      address
      post_code
      country
      city
      dob
      type
      company_name
      company_number
      company_vat
      mobile_verified
      email_verified
      date_created
    }
  }
`;
